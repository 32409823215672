<section class="about-banner">
    <div class="container">
        <!-- <div class="row"> -->
        <!-- <div class=""> -->
        <div class="fakeimg child">
            <img src="../../../../assets/images/myimages1/Rectangle 22.png" class="img-fluid">
            <div class="centeredtxt ">OUR FEATURES</div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
    </div>
</section>
<!-- 
<div class="counter" #counterElement>100</div> -->

<section class="about-features">
    <div class="features-parent22">
        <div class="container">
            <div class="faeture-heading">
                <h5>OUR FEATURES</h5>
                <h1 class="headeingtext">We Serve The Best Service</h1>

            </div>
            <div class="abou-saving-parent">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card border our">
                            <div class="card-body p-5">
                                <img src="../../../../assets/images/myimages1/1 (2).png" alt="">
                                <h6 class="card-subtitle  text-body-secondary">Monthly Payout</h6>
                                <p>Receive regular payments on a monthly basis.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card border our">
                            <div class="card-body p-5">
                                <img src="../../../../assets/images/myimages1/2.png" alt="">
                                <h6 class="card-subtitle  text-body-secondary">Guaranteed Returns</h6>
                                <p>Assured profits with no uncertainty or risk.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card border our">
                            <div class="card-body p-5">
                                <img src="../../../../assets/images/myimages1/3.png" alt="">
                                <h6 class="card-subtitle  text-body-secondary">Completely Transparent Process</h6>
                                <p>Clear and open procedures with no hidden steps.
                                </p>
                            </div>
                        </div>
                    </div>
                </div><br><br>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card border our">
                            <div class="card-body p-5">
                                <img src="../../../../assets/images/myimages1/4.png" alt="">
                                <h6 class="card-subtitle  text-body-secondary">Profit Calculator</h6>
                                <p>Tool to calculate potential earnings accurately.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card border our">
                            <div class="card-body p-5">
                                <img src="../../../../assets/images/myimages1/6.png" alt="">
                                <h6 class="card-subtitle  text-body-secondary">Auto-Withdraw</h6>
                                <p>Automatically transfer funds without manual intervention.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card border our">
                            <div class="card-body p-5">
                                <img src="../../../../assets/images/myimages1/5.png" alt="">
                                <h6 class="card-subtitle  text-body-secondary">3-Step Easy KYC </h6>
                                <p> Simple three-step process for easy verification and compliance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="features">
    <div class="features-parent happy">
        <div class="container">
            <div class="about-saving-parent1 text-center">
                <div class="row featuretxt">
                    <div class="col-md-4 " *ngFor="let counterData of counters; let i = index">
                        <h5 class="counter"  #counterElement>  {{ counterData.number }} {{ counterData.text }} </h5>
                        <p>{{counterData.tittle}}</p>
                    </div>
                  <!--   <div class="col-md-3  column">
                        <h5 class="counter" #counterElement>150K+</h5>
                        <p>Project Completes</p>
                    </div>
                    <div class="col-md-3  column">
                        <h5 class="counter" #counterElement>5+</h5>
                        <p>Years Experience</p>
                    </div>
                    <div class="col-md-3  column">
                        <h5 class="counter" #counterElement>175+</h5>
                        <p>Team Members</p>
                    </div>
 -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-case">
    <div class="container">
        <div class="row special">
            <div class="col-md-12 col-lg-6 col-sm-12 p-5">
                <div class="ban_text">
                    <div class="banner-ctn">
                        <h5>OUR SPECIALITY</h5>
                        <h1 class="headeingtext">
                            We Are The Trusted <br>Managers  
                        </h1>
                        <p class="abouttext">Trusted team of expert delivering precise, innovative solutions
                            tailored to your needs, advancing goals with reliable results. 
                        </p>
                    </div>

                    <div class="download-btn2">
                        <button><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining&pli=1" target="_blank">
                                Learn More</a></button>
                    </div>

                </div>
            </div>

            <div class="col-md-12 col-lg-6 col-sm-12 p-5 progressbartxt">
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-start">
                            Wealth Management
                        </h6>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-end">75%</h6>
                    </div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                        aria-valuemax="100" style="width: 75%"></div>
                </div><br><br>
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-start">
                            Retirement Planning:
                        </h6>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-end">80%</h6>
                    </div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                        aria-valuemax="100" style="width: 80%"></div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-start">
                            Asset Management:
                        </h6>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-end">90%</h6>
                    </div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                        aria-valuemax="100" style="width: 90%"></div>
                </div>
            </div>
        </div>

    </div>
</section>

<section id="faq">
    <section class="about-case">
        <div class="container">
            <div class="row feature">
                <div class="col-md-12 col-lg-6 col-sm-12 p-5">
                    <img src="../../../../assets/images/myimages1/ourfea.png" class="img-fluid">
                </div>
    
                <div class="col-md-12 col-lg-6 col-sm-12 p-5 progressbartxt">
                    <div class="ban_text">
                        <div class="banner-ctn">
                            <h5>CLIENTS QUESTIONS</h5>
                            <h1 class="headeingtext">
                                Related Question
                            </h1>
                            <!-- <p class="abouttext">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. 
                            </p> -->
                        </div>
    
    
    
                    </div>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="true"
                                    aria-controls="flush-collapseOne">
                                    When Can I Withdraw My Principal Amount?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse show"
                                aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">Investment agreements typically have a duration of one
                                    year from the date of investment. Once this period elapses, investors have the option to
                                    withdraw their principal amount or renew the agreement for another term.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                    aria-controls="flush-collapseTwo">
                                    Can I Withdraw My Returns Before the Scheduled Payout Date?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">In most cases, returns on investments are credited to
                                    your bank account on the first Monday of every month. However, some investment plans may
                                    offer the flexibility to withdraw returns at different intervals. It's advisable to
                                    check the terms and conditions of your investment agreement for specific details.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                    What Are the Risks Associated With Investing?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">Investing inherently involves risks, and it's crucial to
                                    be aware of them. While potential returns can be lucrative, there's always a chance of
                                    loss. Understanding your risk appetite and diversifying your investment portfolio can
                                    help mitigate these risks.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour" aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                    How Do I Choose the Right Investment Plan?
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">Choosing the right investment plan requires careful
                                    consideration of various factors, including your financial objectives, time horizon, and
                                    risk tolerance. It's advisable to consult with a financial advisor who can provide
                                    personalized guidance based on your individual circumstances.</div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
    
        </div>
    </section>
</section>