<section class="about-banner">
    <div class="container">
        <div class="row ">
            <div class="">
                <div class="fakeimg child">
                    <img src="../../../../assets/images/myimages/Rectangle 22.png" class="img-fluid">
                    <div class="centeredtxt">Terms & Conditions</div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="about-features">
    <div class="features-parent22">
        <div class="container">



            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <!--  <h1 class="headeingtextpp">Interpretation and Definitions</h1> -->
                        <div class="privacy-ctn">
                            <h5 class="pptext">Terms of Use</h5>
                            <p>The Agreement: The use of this platform and services on this platform provided by
                                Marvellous Info Soft (herein referred to as “Owner”) are subject to the following Terms
                                and Conditions (hereinafter “Terms & Conditions”), all parts and subparts of which is
                                are specifically incorporated by reference here together with the Privacy Policy and
                                Disclaimer. Following are the Terms & Conditions governing your use of MARVELLOUS MINING
                                (the “Platform”), all pages on the Platform and any services provided by or on its
                                platform (“Services”).</p>

                            <p>By accessing either directly or through a hyperlink, the Platform and or purchasing from
                                us, you engage in our “Service” and agree to be bound by the Terms & Conditions
                                including those additional terms & conditions and policies referenced herein and or
                                available by hyperlink. The Terms & Conditions apply to all users of the site, including
                                without limitation to browsers, third-parties and contributors of content. You
                                acknowledge and agree that the Platform may use your personal information in the manner
                                described in our Privacy Policy which sets forth how information collected about you is
                                collected, used and stored.</p>


                            <h5 class="pptext">Definations</h5>

                            <p>Owner, Us, And We: The Owner, as the creator, operator of this Platform, makes the
                                Platform and certain Services on it, available to users. Owner, Us, We, Our, Ours and
                                other first-person pronouns will refer to the Owner, as well as the platform, all
                                employees and affiliates of the Owner.</p>
                            <p>You, the User: you as the user of the Platform, will be referred throughout these Terms &
                                Conditions with second-person pronouns such as You, Your, yours, or as User. For the
                                purpose of this Terms & Conditions, the term “User” or “You” shall mean any natural or
                                legal person, who is accessing the Platform. The term “Your” shall be constructed
                                accordingly.</p>
                            <p>Parties: Collectively, the parties to these Terms and Conditions (the Owner and You) will
                                be referred to as Parties.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext">ASSENT AND ACCEPTANCE</h5>
                        <div class="privacy-ctn">

                            <p>By using the Platform, You warrant that you have read and reviewed these Terms &
                                Conditions and that you agree to be bound by it. If you do not agree to be bound by
                                these Terms & Conditions, please leave the Platform immediately. We only agree to
                                provide accessibility and use of this Platform and Services to you, if you assent to
                                these Terms & Conditions. Furthermore, based on the Services obtained by you, additional
                                terms and conditions (i.e Terms of Service) with respect to the specific service shall
                                apply, which shall be deemed to be the agreement between you and us.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext">ABOUT THIS SITE</h5>
                        <div class="privacy-ctn">

                            <p>This is an online Platform which carries out fundraising. The use of this Platform and
                                its services is not regulated under any law. We reserve the right to refuse the Service
                                at our sole discretion to anyone, for any reason and at any time. You assume all risks
                                associated with dealing with other users with whom you come in contact through this
                                Platform. You agree to use this Platform for lawful purposes without infringing the
                                rights or restricting the use of this Platform by any third-party.</p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext">ACCEPTABLE USE</h5>
                        <div class="privacy-ctn">

                            <p>You agree to not use the Platform or Services for any unlawful purpose or in a way that
                                could damage the Platform, Services and general business of the Owner</p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> AMENDMENTS
                        </h5>
                        <div class="privacy-ctn">

                            <p>We at our sole discretion from time to time may update and modify our Terms and
                                Conditions without any notice to you. You agree that the Owner has the right to modify
                                and amend the Terms & Conditions and it shall be in full force and effective immediately
                                from the day the Terms & Conditions is updated or posted on the Platform and the
                                amendments shall replace any previous version of the Terms & Conditions on the Platform.
                            </p>

                            <P>Furthermore, you agree to routinely monitor these Terms & Conditions and refer to the
                                Effective date posted at top of this Terms & Conditions to note amendments and
                                modifications.</P>
                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> COMMUNICATION
                        </h5>
                        <div class="privacy-ctn">

                            <p>You understand that each time You use the Platform in any manner, you agree to these
                                Terms & Conditions. By agreeing to these Terms & Conditions, you acknowledge that you
                                are interested in availing the Services provided by the Platform and that you have
                                selected and consent to receive communications via phone or e-mail from the Platform
                                telling you about the Services offered by the Platform. You agree that the communication
                                sent to you shall not be construed as spam or bulk under any law prevailing in any
                                country where such communication is received.
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> INDEMNIFICATION
                        </h5>
                        <div class="privacy-ctn">

                            <p>You agree to indemnify Us and any of our affiliates and hold Us harmless against any and
                                all legal claims and demands (including attorney’s fees), which may arise from, or
                                relate to Your use or misuse of the Platform and its Services, Your breach of Terms &
                                Conditions or Your conduct or actions.
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> LICENSE TO USE PLATFORM
                        </h5>
                        <div class="privacy-ctn">

                            <p>We grant you limited, non-exclusive, non-transferable, revocable license to access, and
                                make use of the services on the Platform. All rights not expressly granted to you in
                                these Terms & Conditions, are reserved and retained by the Owner and its affiliates.
                            </p>
                            <p>We may provide You with certain information as a result of Your use of the Platform or
                                Services. Such information may include but is not limited to documents, data, or
                                information of other registered users. You agree not to collect information of any sort
                                of other Users from the Platform or download or copy any information by means of
                                unsolicited access as to communicate directly with them or for any reason whatsoever.
                            </p>
                            <p>Any unauthorized use by you shall terminate your access to the Platform. You agree that
                                You shall not bypass any measures used by the Owner to prevent or restrict access to the
                                Platform.</p>
                            <p>Furthermore, We do not warrant that:
                                This Platform will be constantly available, or available at all; or

                                The information on this Platform is complete, true, accurate or non-misleading.

                                Nothing on the Platform constitutes or is meant to constitute, advice of any kind. All
                                the Services provided on the Platform are unregulated
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> OBLIGATION OF USERS
                        </h5>
                        <div class="privacy-ctn">

                            <p>As the User of the Platform or Services, you may be asked to register with Us. When you
                                do so, You will choose a user identifier, which may be Your email address or another
                                term, as well as a password. You will provide personal information not limited to Your
                                name, email id, contact number etc. You shall be responsible for the accuracy of the
                                information. This identifying information will enable You to use the Platform and its
                                Services. You must not share such identifying information with any third-party. You
                                agree to notify us immediately through an email, in case your identifying information
                                has been compromised. You shall keep Us upraised in case of any changes in such
                                information provided by You.
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> PAYMENT
                        </h5>
                        <div class="privacy-ctn">

                            <p>Should You register for any paid services in the Platform, You agree to pay us such
                                specific monetary amount (“Fees”) for such Services on this Platform. Fees shall be
                                payable through https://cashfree.com, whereby you’ll be redirected to cashfree’s payment
                                gateaway. For User registered as Investor, the money paid or invested shall be kept in a
                                separate bank account, the Startup registered, on reaching the funding goal in the due
                                timeline shall either issue You the share certificate or refund You your money. In case,
                                the Startup doesn’t reach the funding goal, the amount or money paid by you and kept in
                                the separate bank account shall be refunded to your bank account
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> PRIVACY
                        </h5>
                        <div class="privacy-ctn">

                            <p>We view protection of Your privacy as a very important principle. We understand clearly
                                that You and Your Personal Information is one of Our most important assets. We store and
                                process Your Information including any sensitive financial information collected (as
                                defined under the Information Technology Act, 2000), if any, on computers that may be
                                protected by physical as well as reasonable technological security measures and
                                procedures in accordance with Information Technology Act, 2000 and Rules thereunder.
                            </p>
                            <p>We may share personal information with our other corporate entities and affiliates
                                registered with Us.</p>
                            <p>We may disclose personal information if required to do so by law or in the good faith
                                that such disclosure is reasonably necessary to respond to subpoenas, court orders, or
                                other legal process. We may disclose personal information to law enforcement offices,
                                third-party rights owners, or others in the good faith that such disclosure is
                                reasonably necessary to: enforce our Terms & Conditions or Privacy Policy; respond to
                                claims that an advertisement, posting or other content violates the rights of a third
                                party; or protect the rights, property or personal safety of our users or the general
                                public.</p>
                            <p>We and our affiliates will share/sell some or all of your personal information with
                                another business entity should we (or our assets) plan to merge with, or be acquired by
                                that business entity, or reorganization, amalgamation, restructuring of business. Should
                                such a transaction occur, another business entity (or the new combined entity) will be
                                required to follow this privacy policy with respect to your personal information.</p>
                            <p>REVERSE ENGINEERING & SECURITY You agree not to undertake any of the following actions:
                                Reverse engineer or attempt to reverse engineer or disassemble any code or software from
                                or on the Platform and Services
                            </p>
                            <p>Violate the security of the Platform or Services through any unauthorized access,
                                circumvention of encryption or other security tool, data mining or interference to any
                                host, user or network.</p>

                        </div>
                    </div>
                </div>
            </div>



            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> SPAM POLICY
                        </h5>
                        <div class="privacy-ctn">

                            <p>You are strictly prohibited from using Our Platform or any of Our Services for illegal
                                spam activities, including gathering email addresses and personal information from
                                others or sending any mass commercial emails.
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> SERVICE INTERRUPTION
                        </h5>
                        <div class="privacy-ctn">

                            <p>The Owner may need to interrupt Your access to this Platform to perform maintenance,
                                development or any emergency services on scheduled or unscheduled basis. You agree Your
                                access to the Platform may be affected by unanticipated or unscheduled downtime. We
                                shall have no liability for any damage or loss occurred due to the shutdown.
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> THIRD-PARTY LINK & CONTENT
                        </h5>
                        <div class="privacy-ctn">

                            <p>The Owner may occasionally post third-party’s website link or other services. You agree
                                that the Owner shall not be held liable or responsible for any loss or damage as a
                                result of Your use of any third-party’s services linked to our Platform.
                            </p>


                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> GENERAL PROVISION
                        </h5>
                        <div class="privacy-ctn">

                            <p>Governing Law And Jurisdiction

                                This Agreement shall be governed by and construed in accordance with the laws of India.
                                In relation to any legal action or proceedings arising out of or in connection with this
                                Agreement, each of the parties hereto hereby irrevocably submits to the exclusive
                                jurisdiction of the courts of Indore.

                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> Non Waiver
                        </h5>
                        <div class="privacy-ctn">

                            <p>The waiver or failure of either Party to exercise in any respect any right provided for
                                in this Terms & Conditions shall not be deemed a waiver of any further right under this
                                Terms & Conditions
                            </p>


                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> Severability
                        </h5>
                        <div class="privacy-ctn">

                            <p>If any provision of this Terms & Conditions is deemed or held by a court of competent
                                jurisdiction, to be contrary to law or otherwise unenforceable, it shall be enforced to
                                the extent legally permissible and as necessary to reflect the intent of the Parties and
                                shall not affect the remaining provisions of this Terms & Conditions , which shall
                                remain in full force and effect.
                            </p>


                        </div>
                    </div>
                </div>
            </div>



            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext"> Entire Agreement
                        </h5>
                        <div class="privacy-ctn">

                            <p>This Terms & Conditions represents the entire understanding between the Parties with
                                respect to the use of the Platform and subject matter herein and supersedes all prior
                                agreements and understandings and writings of any kind, written or oral, expressed or
                                implied, with respect to the subject matter hereof
                            </p>


                        </div>
                    </div>
                </div>
            </div>

           <!--  <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext">SEBI Caution
                        </h5>
                        <div class="privacy-ctn">

                            <p>Investment in the securities market are subject to market risks, please read all
                                investments, offer and scheme-related documents carefully, before investing. For these
                                Terms of Use, the term “Offer Document(s)” shall refer to a collective term for Offer
                                Document, Scheme Information Document, Statement of Additional Information, Key
                                Information Memorandum, issued by the Investment Company
                            </p>


                        </div>
                    </div>
                </div>
            </div> -->

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext">Suspension and Termination:
                        </h5>
                        <div class="privacy-ctn">

                            <p>The Terms of Use will continue to apply until terminated by either You or the Company as
                                set forth below. If You object to the Terms of Use or are dissatisfied with the
                                Platform, your only recourse is to (i) close Your Account on the Platform; and/or (ii)
                                stop accessing the Platform. In case you violate these Terms of Use and/ or any
                                applicable law, the Company may, at any time and in its sole discretion, terminate Your
                                Account and/or prevent You from accessing the Platform and/or Platform Services. The
                                Company may delist You or block Your future access to the Platform or suspend or
                                terminate your Account if it believes, in its sole and absolute discretion that You have
                                infringed, breached, violated, abused, or unethically manipulated or exploited any term
                                of these Terms of Use or anyway otherwise acted unethically. Notwithstanding anything in
                                this clause, these Terms of Use will survive indefinitely unless and until the Company
                                chooses to terminate them. If You or the Company terminate Your use of the Platform, the
                                Company may delete any Content or other materials relating to Your and the Company shall
                                have no liability to You or any third party for doing so. However, Your transactions
                                details may be preserved by the Company for purposes of tax or regulatory compliance.
                                The Company, in consultation with its group, associates, and subsidiary companies may
                                modify these terms at any time.
                            </p>


                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">

                    <div class="col-md-12">
                        <h5 class="pptext">Electronic Communication (Contact Information)
                        </h5>
                        <div class="privacy-ctn">

                            <p>Electronic communication via email is permitted to both the Parties under this Terms &
                                Conditions. For any query and concerns, we request you to kindly email us at
                            </p>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>