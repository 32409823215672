<section class="about-banner">
    <div class="container">
        <div class="row">
            <div class="">
                <div class="fakeimg child">
                    <img src="../../../../assets/images/myimages1/RectangleABOUT.png" class="img-fluid">
                    <div class="centeredtxt">ABOUT US</div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-features">
    <div class="features-parent22">
        <div class="container">
            <div class="faeture-heading">
                <h5>ABOUT US</h5>
                <h1 class="headeingtext">We Are Finance & <br>Investment Manager</h1>

            </div>
            <div class="abou-saving-parent">
                <div class="row">
                    <div class="col-md-12 col-lg-6 col-sm-12">
                        <p class="abouttext">Marvellous Mining is a forward-thinking investment management service
                            that is rapidly gaining recognition in the financial industry. Our core mission is to offer
                            our clients a superior investment experience by meticulously safeguarding their finances
                            with the highest level of accountability. We are deeply committed to assisting our clients
                            in not only achieving substantial returns on their investments but also in fortifying a
                            secure and prosperous future.

                            At Marvellous Mining, we prioritize mutual growth, viewing our clients' success as
                            integral to our own. Our unwavering dedication lies in furnishing individuals with a
                             dependable avenue for passive income , Empowering 
                        </p>

                    </div>
                    <div class="col-md-12 col-lg-6 col-sm-12">
                        <p class="abouttext"> them to concentrate on their aspirations
                            without the burden of financial uncertainties.By fostering a culture of trust and transparency, we strive to build
                            lasting relationships with our clients, ensuring that their financial well-being remains at
                            the forefront of our services.

                            Our approach is rooted in a profound understanding of the dynamic investment landscape,
                            allowing us to tailor strategies that align with each client's unique goals and risk
                            tolerance. Through a blend of expertise, innovation, and personalized attention, we aim to
                            not only meet but exceed the expectations of those who entrust us with their financial
                            future.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-banner">
    <div class="container">
        <!-- <div class="row"> -->
        <!-- <div class=""> -->
        <div class="fakeimg child about">
            <img src="../../../../assets/images/myimages/Untitled design.gif" class="img-fluid rounded">
            <!-- </div> -->
            <!-- <video src=""></video> -->
        </div>
        <!-- </div> -->
    </div>
</section>

<section class="about-case">
    <div class="container">
        <div class="row aboutnew">
            <div class="col-md-12 col-lg-6 col-sm-12">
                <div class="about-perent">
                    <div class="about-img">
                        <img src="../../../../assets/images/myimages1/Vector.png" alt="">
                    </div>
                    <div class="about-ctn pt-0">
                        <h5>Received Case</h5>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit at faucibus est.</p> -->
                    </div>
                </div>
                <hr>
                <div class="about-perent">
                    <div class="about-img">
                        <img src="../../../../assets/images/myimages1/Vector (1).png" alt="">
                    </div>
                    <div class="about-ctn">
                        <h5>Report Analysis</h5>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit at faucibus est.</p> -->
                    </div>
                </div>
                <hr>
                <div class="about-perent">
                    <div class="about-img">
                        <img src="../../../../assets/images/myimages1/Group 57.png" alt="">
                    </div>
                    <div class="about-ctn">
                        <h5>Collect Evidence</h5>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit at faucibus est.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-sm-12">
                <div class="fakeimg child">
                    <div class="ban_text">
                        <div class="banner-ctn p-0">
                            <h5>HOW WE WORK</h5>
                            <h1 class="headeingtext">
                                Leading The Best <br>Invest Manager Team
                            </h1>
                            <p class="abouttext">At our esteemed organization, we follow a well-defined and efficient
                                work process to deliver exceptional financial and investment management services. Our
                                systematic approach ensures that we thoroughly analyze each case, develop tailored
                                strategies, and implement effective solutions to help our clients optimize their
                                financial potential.

                            </p>
                            <!--   <p>We are always in the pursuit of making our clients earn great <br> returns on their investments and
                          paving
                          their way towards a <br> secure and stable future. We believe in mutual growth and we <br> want to
                          provide people
                          with a reliable passive earning source <br> that helps them focus on their goals.</p> -->
                        </div>
                        <!-- <div class="form-parent32">
                            <h1 class="client" #counterElement> 700  </h1><strong>+</strong><span>WE HAVE MORE THAN 700+<br> HAPPY CLIENTS IN INDIA </span>
                        </div> -->

                        <div class="download-btn1">
                            <button><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">
                                    Learn More
                                </a></button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- <section class="features">
    <div class="features-parent about">
        <div class="container">
            <div class="about-saving-parent1">
                <div class="row">
                    <div class="col-md-12 col-lg-6 col-sm-12 column">
                        <div class="">
                          
                            <p>ACHIEVEMENT</p>
                        </div>
                        <h5 class="about-textsaving">Over 1300+ <br> Complete work & <br> Still counting</h5>
                        <p class="">Over the years, we have achieved remarkable success for our clients. By consistently
                            delivering outstanding results, we have completed over 1300 projects and counting. Our
                            proven track record of success is a testament to our expertise and commitment to client
                            satisfaction.
                        </p>
                    </div>
                    <div class="col-md-12 col-lg-6 col-sm-12 column">
                        <div class="row">
                            <div class="col-md-6 abt-txt">
                                <h5>5K+</h5>
                                <p>Satisfied Customers</p>
                                <br><br><br>
                                <h5>250</h5>
                                <p>Professional Worker</p>

                            </div>
                            <div class="col-md-6 abt-txt">
                                <h5>100K</h5>
                                <p>Satisfied Customers</p>
                                <br><br><br>
                                <h5>10K</h5>
                                <p>Years of experience</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="about-features">
    <div class="features-about">
        <div class="container">
            <div class="faeture-heading">
                <h5>OUR EXPERT</h5>
                <h1 class="headeingtext">Our Professional Team</h1>

            </div>
            <div class="team-saving-parent">
                <div class="row p-0 m-0">
                    <div class="col-md-3  col-sm-12 p-0">
                        <img src="../../../../assets/images/myimages/Rectangle 24.png" alt="">
                        <div class="about-team">
                            <h5>Emily Elfie</h5>
                            <p>
                                Chairman and CEO
                            </p>
                        </div>

                    </div>
                    <div class="col-md-3 col-sm-12 p-0">
                        <img src="../../../../assets/images/myimages/Rectangle 25.png" alt="">
                        <div class="about-team">
                            <h5>Emily Elfie</h5>
                            <p>
                                Chairman and CEO
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3  col-sm-12 p-0">
                        <img src="../../../../assets/images/myimages/Rectangle 26.png" alt="">
                        <div class="about-team">
                            <h5>Emily Elfie</h5>
                            <p>
                                Chairman and CEO
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3  col-sm-12 p-0">
                        <img src="../../../../assets/images/myimages/Rectangle 27.png" alt="">
                        <div class="about-team">
                            <h5>Emily Elfie</h5>
                            <p>
                                Chairman and CEO
                            </p>
                        </div>
                    </div>
                </div>
                <div class="download-btn1">
                    <button><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining&pli=1">
                            View All Team
                        </a></button>
                </div>
            </div>
        </div>
    </div>
</section> -->