<section class="about-banner">
    <div class="container">
        <div class="row ">
            <div class="">
                <div class="fakeimg child">
                    <img src="../../../../assets/images/myimages1/Rectangle 22.png" class="img-fluid">
                    <div class="centeredtxt">PRIVACY POLICY</div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-features">
    <div class="features-parent22">
        <div class="container">

            <div class="faeture-heading">
                <p>This Privacy Policy describes Our policies and procedures on the collection, use and <br> disclosure
                    of
                    Your information when You use the Service and tells You about Your privacy <br> rights and how the
                    law
                    protects
                    You.
                </p>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">
                   
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Interpretation and Definitions</h1>
                        <div class="privacy-ctn">
                            <h5 class="pptext">Interpretation</h5>
                            <p>The words of which the initial letter is capitalized have meanings defined under the
                                following conditions. The following definitions shall have the same meaning regardless
                                of whether they appear in singular or in plural.</p>
                            <h5 class="pptext">Definations</h5>

                            <p>For the purposes of this Privacy Policy:</p>

                            <ul class="ppul">
                                <li><b class="pptext"> Account</b> means a unique account created for You to access our
                                    Service or parts of our Service.</li>
                                <li><b class="pptext"> Affiliate </b> means an entity that controls, is controlled by or
                                    is under common control with a party, where “control” means ownership of 50% or more
                                    of the shares, equity interest or other securities entitled to vote for election of
                                    directors or other managing authority.
                                </li>

                                <li><b class="pptext"> Application </b> means the software program provided by the
                                    Company downloaded by You on any electronic device.</li>
                                <li><b class="pptext"> Company </b>(referred to as either “the Company”, “We”, “Us” or
                                    “Our” in this Agreement) refers to Marvellous Info Soft.</li>

                                <li><b class="pptext"> Cookies </b> are small files that are placed on Your computer,
                                    mobile device or any other device by a website, containing the details of Your
                                    browsing history on that website among its many uses.</li>

                                <li><b class="pptext"> Country </b> refers to: Madhya Pradesh, India
                                </li>
                                <li><b class="pptext"> Device </b> means any device that can access the Service such as
                                    a computer, a cellphone or a digital tablet.
                                </li>

                                <li><b class="pptext"> Personal Data </b> is any information that relates to an
                                    identified or identifiable individual..
                                </li>

                                <li><b class="pptext"> Service </b> refers to the Application or the Website or both.
                                </li>

                                <li><b class="pptext"> Service Provider </b> means any natural or legal person who
                                    processes the data on behalf of the Company. It refers to third-party companies or
                                    individuals employed by the Company to facilitate the Service, to provide the
                                    Service on behalf of the Company, to perform services related to the Service or to
                                    assist the Company in analyzing how the Service is used..
                                </li>
                                <li><b class="pptext"> Third-party Social Media Service </b> refers to any website or
                                    any social network website through which a User can log in or create an account to
                                    use the Service.
                                </li>

                                <li><b class="pptext"> Usage Data </b> refers to data collected automatically, either
                                    generated by the use of the Service or from the Service infrastructure itself (for
                                    example, the duration of a page visit).
                                </li>

                                <li><b class="pptext"> Website </b> refers to Marvellous Info Soft, accessible from
                                    https://marvellousinfo.com/

                                </li>
                                <li><b class="pptext"> You </b> means the individual accessing or using the Service, or
                                    the company, or other legal entity on behalf of which such individual is accessing
                                    or using the Service, as applicable.

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">
                
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Collecting and Using Your Personal Data</h1>
                        <div class="privacy-ctn">
                            <h4 class="pptext">Types of Data Collected</h4>
                            <h5 class="pptext">Personal Data</h5>
                            <p>While using Our Service, We may ask You to provide Us with certain personally
                                identifiable information that can be used to contact or identify You. Personally
                                identifiable information may include, but is not limited to:</p>
                            <ul class="ppul">
                                <li>Email address</li>
                                <li>First name and last name</li>
                                <li>Phone number</li>
                                <li>Address, State, Province, ZIP/Postal code, City</li>
                                <li>Usage Data</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">
                   
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Usage Data</h1>
                        <div class="privacy-ctn">

                            <p>Usage Data is collected automatically when using the Service.</p>
                            <p>Usage Data may include information such as Your Device’s Internet Protocol address (e.g.
                                IP address), browser type, browser version, the pages of our Service that You visit, the
                                time and date of Your visit, the time spent on those pages, unique device identifiers
                                and other diagnostic data.</p>
                            <p>When You access the Service by or through a mobile device, We may collect certain
                                information automatically, including, but not limited to, the type of mobile device You
                                use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
                                operating system, the type of mobile Internet browser You use, unique device identifiers
                                and other diagnostic data.</p>
                            <p>We may also collect information that Your browser sends whenever You visit our Service or
                                when You access the Service by or through a mobile device.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">
                   
                    <div class="col-md-12">
                        
                        <h1 class="headeingtextpp">Tracking Technologies  and Cookies</h1>

                        <div class="privacy-ctn">

                            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and
                                store certain information. Tracking technologies used are beacons, tags, and scripts to
                                collect and track information and to improve and analyze Our Service. The technologies
                                We use may include:</p>

                            <ul class="ppul">
                                <li>Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can
                                    instruct Your browser to refuse all Cookies or to indicate when a Cookie is being
                                    sent. However, if You do not accept Cookies, You may not be able to use some parts
                                    of our Service. Unless you have adjusted Your browser setting so that it will refuse
                                    Cookies, our Service may use Cookies.</li>
                                <li>Flash Cookies.Certain features of our Service may use local stored objects (or
                                    Flash Cookies) to collect and store information about Your preferences or Your
                                    activity on our Service. Flash Cookies are not managed by the same browser settings
                                    as those used for Browser Cookies. For more information on how You can delete Flash
                                    Cookies, please read “Where can I change the settings for disabling, or deleting
                                    local shared objects?” available
                                    at https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects
                                    flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                                </li>
                                <li>Web Beacons. Certain sections of our Service and our emails may contain small
                                    electronic files known as web beacons (also referred to as clear gifs, pixel tags,
                                    and single-pixel gifs) that permit the Company, for example, to count users who have
                                    visited those pages or opened an email and for other related website statistics (for
                                    example, recording the popularity of a certain section and verifying system and
                                    server integrity).</li>

                            </ul>
                            <p>Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on Your
                                personal computer or mobile device when You go offline, while Session Cookies are
                                deleted as soon as You close Your web browser. Learn more about cookies: Cookies: What
                                Do They Do?.</p>

                            <p>We use both Session and Persistent Cookies for the purposes set out below:</p>

                            <h5 class="pptext">Necessary / Essential Cookies</h5>
                            <p>Type: Session Cookies</p>
                            <p>Administered by: Us</p>
                            <p>Purpose: These Cookies are essential to provide You with services available through the
                                Website and to enable You to use some of its features. They help to authenticate users
                                and prevent fraudulent use of user accounts. Without these Cookies, the services that
                                You have asked for cannot be provided, and We only use these Cookies to provide You with
                                those services.</p>

                            <h5 class="pptext">Cookies Policy / Notice Acceptance Cookies</h5>
                            <p>Type: Persistent Cookies</p>
                            <p>Administered by: Us</p>
                            <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
                            </p>


                            <h5 class="pptext">Functionality Cookies</h5>
                            <p>Type: Persistent Cookies</p>
                            <p>Administered by: Us</p>
                            <p>Purpose: These Cookies allow us to remember choices You make when You use the Website,
                                such as remembering your login details or language preference. The purpose of these
                                Cookies is to provide You with a more personal experience and to avoid You having to
                                re-enter your preferences every time You use the Website.</p>

                            <p>For more information about the cookies we use and your choices regarding cookies, please
                                visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">
                   
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Use of Your Personal Data</h1>
                        <div class="privacy-ctn">

                            <p>The Company may use Personal Data for the following purposes:
                            </p>

                            <ul class="ppul">
                                <li><b class="pptext"> To provide and maintain our Service</b> including to monitor the
                                    usage of our Service.</li>
                                <li><b class="pptext"> To manage Your Account: </b> to manage Your registration as a
                                    user of the Service. The Personal Data You provide can give You access to different
                                    functionalities of the Service that are available to You as a registered user
                                </li>

                                <li><b class="pptext"> For the performance of a contract: </b> the development,
                                    compliance and undertaking of
                                    the purchase contract for the products, items or services You have purchased or of
                                    any other contract with Us through the Service.</li>
                                <li><b class="pptext"> Company </b>(referred to as either “the Company”, “We”, “Us” or
                                    “Our” in this Agreement) refers to Marvellous Info Soft.</li>

                                <li><b class="pptext"> To contact You: </b> To contact You by email, telephone calls,
                                    SMS, or other equivalent forms of electronic communication, such as a mobile
                                    application’s push notifications regarding updates or informative communications
                                    related to the functionalities, products or contracted services, including the
                                    security updates, when necessary or reasonable for their implementation.</li>

                                <li><b class="pptext"> To provide You </b> with news, special offers and general
                                    information about other goods, services and events which we offer that are similar
                                    to those that you have already purchased or enquired about unless You have opted not
                                    to receive such information.
                                </li>
                                <li><b class="pptext"> To manage Your requests: </b> To attend and manage Your requests
                                    to Us.
                                </li>

                                <li><b class="pptext"> For business transfers: </b>We may use Your information to
                                    evaluate or conduct a merger, divestiture, restructuring, reorganization,
                                    dissolution, or other sale or transfer of some or all of Our assets, whether as a
                                    going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
                                    Personal Data held by Us about our Service users is among the assets transferred.
                                </li>

                                <li><b class="pptext"> For other purposes: </b> We may use Your information for other
                                    purposes, such as data analysis, identifying usage trends, determining the
                                    effectiveness of our promotional campaigns and to evaluate and improve our Service,
                                    products, services, marketing and your experience.
                                    We may share Your personal information in the following situations:
                                </li>

                                <li><b class="pptext"> With Service Providers: </b> We may share Your personal
                                    information with Service Providers to monitor and analyze the use of our Service, to
                                    contact You.
                                </li>
                                <li><b class="pptext"> For business transfers:</b> We may share or transfer Your
                                    personal information in connection with, or during negotiations of, any merger, sale
                                    of Company assets, financing, or acquisition of all or a portion of Our business to
                                    another company.
                                </li>

                                <li><b class="pptext"> With Affiliates:</b> We may share Your information with Our
                                    affiliates, in which case we will require those affiliates to honor this Privacy
                                    Policy. Affiliates include Our parent company and any other subsidiaries, joint
                                    venture partners or other companies that We control or that are under common control
                                    with Us.
                                </li>

                                <li><b class="pptext"> With business partners: </b> We may share Your information with
                                    Our business partners to offer You certain products, services or promotions.

                                </li>
                                <li><b class="pptext"> With other users: </b> when You share personal information or
                                    otherwise interact in the public areas with other users, such information may be
                                    viewed by all users and may be publicly distributed outside. If You interact with
                                    other users or register through a Third-Party Social Media Service, Your contacts on
                                    the Third-Party Social Media Service may see Your name, profile, pictures and
                                    description of Your activity. Similarly, other users will be able to view
                                    descriptions of Your activity, communicate with You and view Your profile.

                                </li>
                                <li><b class="pptext"> With Your consent </b>We may disclose Your personal information
                                    for any other purpose with Your consent.
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">
                   
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Retention of Your Personal Data
                        </h1>
                        <div class="privacy-ctn">

                            <p>The Company will retain Your Personal Data only for as long as is necessary for the
                                purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
                                the extent necessary to comply with our legal obligations (for example, if we are
                                required to retain your data to comply with applicable laws), resolve disputes, and
                                enforce our legal agreements and policies. The Company will also retain Usage Data for
                                internal analysis purposes. Usage Data is generally retained for a shorter period of
                                time, except when this data is used to strengthen the security or to improve the
                                functionality of Our Service, or We are legally obligated to retain this data for longer
                                time periods .</p>

                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">
                    
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Transfer of Your Personal Data

                        </h1>
                        <div class="privacy-ctn">

                            <p>Your information, including Personal Data, is processed at the Company’s operating
                                offices and in any other places where the parties involved in the processing are
                                located. It means that this information may be transferred to — and maintained on —
                                computers located outside of Your state, province, country or other governmental
                                jurisdiction where the data protection laws may differ than those from Your
                                jurisdiction. Your consent to this Privacy Policy followed by Your submission of such
                                information represents Your agreement to that transfer. The Company will take all steps
                                reasonably necessary to ensure that Your data is treated securely and in accordance with
                                this Privacy Policy and no transfer of Your Personal Data will take place to an
                                organization or a country unless there are adequate controls in place including the
                                security of Your data and other personal information.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">
                  
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Disclosure of Your Personal Data
                        </h1>
                        <div class="privacy-ctn">

                            <h5 class="pptext">Business Transactions
                            </h5>
                            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may
                                be transferred. We will provide notice before Your Personal Data is transferred and
                                becomes subject to a different Privacy Policy.

                            </p>
                            <br>
                            <h5 class="pptext">Law enforcement

                            </h5>
                            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data
                                if required to do so
                                by law or in response to valid requests by public authorities (e.g. a court or a
                                government agency).

                            </p>
                            <br>
                            <h5 class="pptext">Other legal requirements
                            </h5>
                            <p>The Company may disclose Your Personal Data in the good faith belief that such action is
                                necessary to:
                            </p>

                            <ul>
                                <li>Comply with a legal obligation
                                </li>
                                <li>Protect and defend the rights or property of the Company
                                </li>
                                <li>Prevent or investigate possible wrongdoing in connection with the Service
                                </li>
                                <li>Protect the personal safety of Users of the Service or the public
                                </li>
                                <li>Protect against legal liability
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>



            <div class="abou-saving-parent">
                <div class="row privacy">
                  
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Security of Your  Personal Data


                        </h1>
      
                        <div class="privacy-ctn">

                            <p>The security of Your Personal Data is important to Us, but remember that no method of
                                transmission over the Internet, or method of electronic storage is 100% secure. While We
                                strive to use commercially acceptable means to protect Your Personal Data, We cannot
                                guarantee its absolute security.

                            </p>

                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">
                    
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Children’s Privacy
                        </h1>
                        <div class="privacy-ctn">

                            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect
                                personally identifiable information from anyone under the age of 13. If You are a parent
                                or guardian and You are aware that Your child has provided Us with Personal Data, please
                                contact Us. If We become aware that We have collected Personal Data from anyone under
                                the age of 13 without verification of parental consent, We take steps to remove that
                                information from Our servers. If We need to rely on consent as a legal basis for
                                processing Your information and Your country requires consent from a parent, We may
                                require Your parent’s consent before We collect and use that information.

                            </p>

                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">
                    
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Links to Other  Websites </h1>
                        <div class="privacy-ctn">

                            <p>Our Service may contain links to other websites that are not operated by Us. If You click
                                on a third party link, You will be directed to that third party’s site. We strongly
                                advise You to review the Privacy Policy of every site You visit. We have no control over
                                and assume no responsibility for the content, privacy policies or practices of any third
                                party sites or services.



                            </p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="abou-saving-parent">
                <div class="row privacy">
                    
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Changes to this Privacy Policy


                        </h1>
                        <div class="privacy-ctn">

                            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by
                                posting the new Privacy Policy on this page. We will let You know via email and/or a
                                prominent notice on Our Service, prior to the change becoming effective and update the
                                “Last updated” date at the top of this Privacy Policy. You are advised to review this
                                Privacy Policy periodically for any changes. Changes to this Privacy Policy are
                                effective when they are posted on this page.





                            </p>

                        </div>
                    </div>
                </div>
            </div>


            <div class="abou-saving-parent">
                <div class="row privacy">
                   
                    <div class="col-md-12">
                        <h1 class="headeingtextpp">Contact Us </h1>
                        <div class="privacy-ctn">

                            <p>If you have any questions about this Privacy Policy, You can contact us:
                            </p>
                            <ul>
                                <li>By email: care.marvellous.info@gmail.com
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>