<!-- whatsapp icon -->
<!-- <div class="w-parent">
  <div class="whatsapp">
    <a href="https://whatsapp.com/channel/0029VaDXqif1t90Xgyu3FW1m"> <img  src="../../../assets/images/myimages/WhatsApp_icon.png.webp" class="img-fluid" alt=""></a>
    <a href=" https://wa.me/918962281128?text=I'm%20interested%20in%20your%20Services%20for%20sale" target="_blank">
     
    </a>
  </div>
</div> -->
<!-- touch with us sectionb -->
<section class="get">
  <div class="get-parent">
    <div class="features-parent22">
      <div class="container">
        <div class="faeture-heading1  ">
          <!--   <h5>GET IN TOUCH WITH US</h5> -->
          <h1 class="fontsize">Download our Marvellous Mining App<br> now and start your journey to a secure<br> and
            prosperous future.!</h1>
          <div class="download-btn1">
            <button class="getstart footer-btn hover"><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Get Started</a></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- touch with us section -->
<section class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-lg-3 col-sm-12">
        <div class="f-menu">
          <h1 class="heading1">About Us</h1>
          <p class="fabout-ctn">Marvellous Mining excels in <br>  transparent mining services, <br> maximizing  earnings for
            clients. <br> Trust us for secure passive income <br> and expert guidance in the mining industry.</p>
        </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12">
        <div class="f-menu">
          <h1 class="heading1">Quick Links</h1>
          <ul class="footer-nav">
            <li><a [routerLink]="['/about']">About Us</a></li>
            <li><a [routerLink]="['/Ourfeature']">Our Feature</a></li>
            <!-- <li><a [routerLink]="['/Ourfeature']" href="#faq">Case Studies</a></li> -->
            <li><a [routerLink]="['/pricing']">Pricing</a></li>
            <li><a [routerLink]="['/blog']">Blog</a></li>
            <li><a [routerLink]="['/ContactUs']">Contact Us</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-12">
        <div class="f-menu">
          <h1 class="heading1">Useful Links</h1>
          <ul class="footer-nav">
            <li><a [routerLink]="['/PrivacyPolicy']">Privacy Policy</a></li>
            <li><a [routerLink]="['/TermsConditions']">Terms & Conditions</a></li>
            <!-- <li><a href="#faq">Disclaimer</a></li> -->
            <li><a href="#form">Support</a></li>
            <li><a href="#question">FAQ</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-5 col-lg-5 col-sm-12 ">
        <div class="f-menu">
          <h1 class="heading1">Subscribe Our Newsletter</h1>
          <div class="form-box">
            <div class="input-main">
              <input type="email" name="email" placeholder="Your Email">
            </div>
            <button class="submit" type="submit">Go</button>
          </div>
          <div class="social-links">
            <ul class="social-nav">
              <li><a href="https://www.facebook.com/Marvellousmin" target="_blank"><img
                    src="../../../assets/images/myimages/Group 137.svg" alt=""></a></li>
              <li><a href="https://www.instagram.com/marvellousinvestments/" target="_blank"><img
                    src="../../../assets/images/myimages/Group 138.svg" alt=""></a></li>
              <li><a href="https://whatsapp.com/channel/0029VaDXqif1t90Xgyu3FW1m" target="_blank"><img
                    src="../../../assets/images/myimages/Group 139.svg" alt=""></a></li>
              <!-- <li><a href="#form"><img src="../../../assets/images/myimages/2.png" alt=""></a></li> -->
              <!-- <li><a href="#form"><img src="../../../assets/images/myimages/2.png" alt="">  </a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
   
  </div>
  <br>
  <hr>
  <div class="copyright text-center">
    Copyright © 2024. Marvellous Mining. All rights reserved.
  </div>
</section>
