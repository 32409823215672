<section class="about-banner">
    <div class="container">
        <!-- <div class="row"> -->
        <!-- <div class=""> -->
        <div class="fakeimg child">
            <img src="../../../../assets/images/myimages1/RectanglePRICING.png" class="img-fluid">
            <div class="centeredtxt ">PRICING</div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
    </div>
</section>

<section class="inerpricing">
    <div class="container">
        <div class="faeture-heading">
            <h5>PRICING PLANS</h5>
            <h1 class="headeingtext">Choose your best. </h1>
            <p>Choose from different plans to grow your money the way you want. </p>
        </div>
        <div class="row justify-content-between" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
            <div class="col-md-12  col-lg-4 col-sm-12">
                <div class="card black plan">
                    <div class="card-body p-5">
                        <img src="../../../assets/images/myimages/Group 16 (6).svg" alt="">
                        <h6 class="card-subtitle  text-body-secondary plan">BASIC PLAN</h6>
                        <p>Steady returns, ideal for beginners seeking consistent growth.</p>
                        <h1 class="plan-num">₹1,000-49,999</h1>
                        <div class="planbtn-p">
                            <button class="plan-btn1"><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Choose Plan
                            </a></button>
                            
                          </div>
                        <div class="project">
                            <p><img src="../../../../assets/images/myimages1/Group 22.png" alt=""><span>Assured Monthly
                                    3.08%</span></p>
                            <p><img src="../../../../assets/images/myimages1/Group 22.png" alt=""><span>Assured Annual
                                    37%</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12  col-lg-4 col-sm-12">
                <div class="card border plan">
                    <div class="card-body p-5">
                        <img src="../../../../assets/images/myimages/pricing.svg" alt="">
                        <h6 class="card-subtitle  text-body-secondary plan">MASTER PLAN</h6>
                        <p>Enhanced returns, suited for investors aiming for accelerated growth.</p>
                        <h1 class="plan-num">₹50,000-2,49,999</h1>
                        <div class="planbtn-p">
                            <button class="plan-btn1"><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Choose Plan
                            </a></button>
                            
                          </div>
                        <div class="project">
                            <p><img src="../../../../assets/images/myimages1/Group 108.png" alt=""><span>Assured Monthly
                                    3.5%</span></p>
                            <p><img src="../../../../assets/images/myimages1/Group 108.png" alt=""><span>Assured Annual
                                    42%</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12  col-lg-4 col-sm-12">
                <div class="card black plan">
                    <div class="card-body p-5">
                        <img src="../../../../assets/images/myimages/Group 16 (8).svg" alt="">
                        <h6 class="card-subtitle  text-body-secondary plan">ADVANCE PLAN</h6>
                        <p>High returns, tailored for experienced investors seeking maximum potential.</p>
                        <h1 class="plan-num">₹2,50,000-9,99,999</h1>
                        <div class="planbtn-p">
                            <button class="plan-btn1"><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Choose Plan
                            </a></button>
                            
                          </div>
                        <div class="project">
                            <p><img src="../../../../assets/images/myimages1/Group 22.png" alt=""><span>Assured Monthly
                                    3.75%</span></p>
                            <p><img src="../../../../assets/images/myimages1/Group 22.png" alt=""><span>Assured Annual
                                    45%</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- speciality section -->
<section class="speciality">
    <div class="container">
        <div class="row special">
            <div class="col-md-12 col-lg-6 col-sm-12 p-5">
                <div class="ban_text">
                    <div class="banner-ctn">
                        <h5>OUR SPECIALITY</h5>
                        <h1 class="headeingtext">
                            We Are The Trusted <br>Scientist Experts
                        </h1>
                        <p class="abouttext">Trusted team of expert scientists delivering precise, innovative solutions
                            tailored to your needs, advancing goals with reliable results. 
                        </p>
                    </div>

                    <div class="download-btn2">
                        <button><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining&pli=1">
                                Learn More</a></button>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-lg-6 col-sm-12 p-5 progressbartxt">
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-start">
                            Wealth Management
                        </h6>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-end">75%</h6>
                    </div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                        aria-valuemax="100" style="width: 75%"></div>
                </div><br><br>
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-start   ">
                            Retirement Planning
                        </h6>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-end">80%</h6>
                    </div>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                        aria-valuemax="100" style="width: 80%"></div>
                </div>
                <br><br>
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-start   ">
                            Asset Management
                        </h6>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-end">90%</h6>
                    </div>
                </div>
                <div class="progress">
                    <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0"
                        aria-valuemax="100" style="width: 90%"></div>
                </div>
            </div>
        </div>

    </div>
</section>


<!-- our features inner pricing -->
<section class="iner-pricing">
    <div class="container">
        <div class="faeture-heading">
            <h5>OUR FEATURES</h5>
            <h1 class="headeingtext price">What’s Our Features</h1>
            <!--  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus <br> nec ullamcorper mattis,
          pulvinar dapibus leo.</p> -->
        </div>
        <div class="saving-parent1 price">
            <div class="row" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                <div class="col-md-12 col-lg-4 col-sm-12 column">
                    <div class="feature-icon">
                        <!-- <i data-feather="dollar-sign" [size]="33" class=" cursor-pointer"></i> -->
                        <img src="../../../assets/images/icons/Vector.svg" alt="">
                    </div>
                    <h5 class="textsaving">Saving Strategies</h5>
                    <p class="content">Customized investment plans for all, catering to individual goals and
                        circumstances,
                        whether novice or seasoned. </p>
                </div>
                <div class="col-md-12 col-lg-4 col-sm-12 column">
                    <div class="feature-icon">
                        <img src="../../../assets/images/icons/Vector (1).svg" alt="">
                    </div>
                    <h5 class="textsaving">Competitive Price</h5>
                    <p class="content">Tailored investment plans for everyone, meeting unique goals and circumstances,
                        whether
                        new or experienced.</p>
                </div>
                <div class="col-md-12 col-lg-4 col-sm-12 column">
                    <div class="feature-icon">
                        <img src="../../../assets/images/icons/streamline_customer-support-1-solid.svg" alt="">
                    </div>
                    <h5 class="textsaving">24/7 Support</h5>
                    <p class="content">Dedicated financial experts provide guidance, answer questions, and ensure your
                        path to
                        financial success.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- vcgv -->
<div id="question">
    <section class="iner-faq">
        <div class="container">
            <div class="faeture-heading">
                <h5>FAQS</h5>
                <h1 class="headeingtext price faq">Frequently asked questions</h1>
                <!--  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus <br> nec ullamcorper mattis,
                  pulvinar dapibus leo.</p> -->
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne" aria-expanded="true"
                                    aria-controls="flush-collapseOne">
                                    How Much Can I Earn From My Investments?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse show"
                                aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">The earnings from your investments depend on the type of
                                    plan you've chosen. At our firm, we offer competitive returns, with the highest return
                                    reaching up to 45%. It's essential to select an investment plan that aligns with your
                                    financial goals and risk tolerance.
                                </div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                    aria-controls="flush-collapseTwo">
                                    What Is KYC, and Why Is It Necessary?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">KYC, or Know Your Customer, is a regulatory requirement
                                    designed to prevent fraud and money laundering. As part of the KYC process, investors
                                    are required to provide identifying information and documentation. This helps ensure the
                                    legitimacy of investment transactions and protects both investors and financial
                                    institutions.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree" aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                    How Do I Complete My KYC and Bank Details?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">Completing your KYC and providing bank details is a
                                    straightforward process. Most investment firms have online portals where you can upload
                                    the necessary documents securely. Once verified, your bank account details will be
                                    linked to your investment account for seamless transactions.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour" aria-expanded="false"
                                    aria-controls="flush-collapseThree">
                                    What Happens If I Miss the Deadline for KYC Submission?
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">
                                    Missing the deadline for KYC submission may result in delays in processing your
                                    investment transactions. It's essential to adhere to the KYC requirements and submit the
                                    necessary documents promptly to avoid any inconvenience.
                                </div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingFive">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive" aria-expanded="false"
                                    aria-controls="flush-collapseFour">
                                    Can I Withdraw My Returns Before the Scheduled Payout Date?
                                </button>
                            </h2>
                            <div id="flush-collapseFive" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">In most cases, returns on investments are credited to
                                    your bank account on the first Monday of every month. However, some investment plans may
                                    offer the flexibility to withdraw returns at different intervals. It's advisable to
                                    check the terms and conditions of your investment agreement for specific details.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingSix">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix" aria-expanded="false"
                                    aria-controls="flush-collapseFive">
                                    How Can I Maximize My Investment Returns?
                                </button>
                            </h2>
                            <div id="flush-collapseSix" class="accordion-collapse collapse"
                                aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body feature">Maximizing investment returns requires a strategic
                                    approach and careful planning. Diversifying your portfolio, staying informed about
                                    market trends, and regularly reviewing your investment strategy are essential steps.
                                    Additionally, working with a trusted financial advisor can help you make informed
                                    decisions and optimize your returns over time.
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
    
                <div class="col-md-6">
                    <div class="accordion accordion-flush" id="accordionFlushExample1">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading1">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse1" aria-expanded="true" aria-controls="flush-collapse1">
                                    How Do I Choose the Right Investment Plan?
                                </button>
                            </h2>
                            <div id="flush-collapse1" class="accordion-collapse collapse show"
                                aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body feature">Choosing the right investment plan requires careful
                                    consideration of various factors, including your financial objectives, time horizon, and
                                    risk tolerance. It's advisable to consult with a financial advisor who can provide
                                    personalized guidance based on your individual circumstances.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading2">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    When Can I Withdraw My Principal Amount?
                                </button>
                            </h2>
                            <div id="flush-collapse2" class="accordion-collapse collapse" aria-labelledby="flush-heading2"
                                data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body feature">Investment agreements typically have a duration of one
                                    year from the date of investment. Once this period elapses, investors have the option to
                                    withdraw their principal amount or renew the agreement for another term.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading3">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    Are There Any Penalties for Early Withdrawal of Investments? 
                                </button>
                            </h2>
                            <div id="flush-collapse3" class="accordion-collapse collapse" aria-labelledby="flush-heading3"
                                data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body feature">
                                    Some investment agreements may impose penalties or fees for early withdrawal of funds
                                    before the maturity date. It's crucial to review the terms and conditions of your
                                    investment agreement to understand any potential penalties associated with early
                                    withdrawal.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    What Are the Tax Implications of Investment Returns?
                                </button>
                            </h2>
                            <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4"
                                data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body feature">Investment returns may be subject to taxation, depending
                                    on your country's tax laws and regulations. It's advisable to consult with a tax advisor
                                    to understand the tax implications of your investment returns and ensure compliance with
                                    relevant tax laws.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading5">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    How is the transfer process to take the test?
                                </button>
                            </h2>
                            <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5"
                                data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body feature">Placeholder content for this accordion, which is
                                    intended to demonstrate the <code>.accordion-flush</code> class. This is the second
                                    item's accordion body. Let's imagine this being filled with some actual content.</div>
                            </div>
                        </div>
    
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading6">
                                <button class="accordion-button feature collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                                    Can I Change My Investment Plan Midway?
                                </button>
                            </h2>
                            <div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="flush-heading6"
                                data-bs-parent="#accordionFlushExample1">
                                <div class="accordion-body feature">In some cases, investors may have the option to switch
                                    or modify their investment plan during the term of the agreement. However, this may be
                                    subject to certain restrictions or fees. It's essential to consult with your investment
                                    provider to explore your options and understand any implications of changing your
                                    investment plan.
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
    
            </div>
        </div>
    </section>
</div>