<section class="about-banner">
  <div class="container">
    <div class="row">
      <div class="">
        <div class="fakeimg child">
          <img src="../../../../assets/images/myimages1/Rectangleblog.png" class="img-fluid">
          <div class="centeredtxt">BLOG</div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- blogs -->
<section class="about-features">
  <div class="features-parent22">
    <div class="container">
      <div class="faeture-heading">
        <h5>BLOG</h5>
        <h1 class="headeingtext">See Our Project We Have Done</h1>

      </div>
      <div class="abou-saving-parent">
        <div class="row" >
          <!-- <div class="col-md-1">

                    </div> -->
          <div class="col-sm-12 col-md-6 col-lg-6 blog1"  *ngFor="let item of blogs">
            <div class="blog-image"><img class="img-fluid" src="{{item.bannerImage}}">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>{{item.title}}</h4>
                    <p class="">
                      {{item.description}}
                      <!-- Cryptocurrency has become a hot topic in recent years due to its potential to revolutionize the
                      way we use money. Cryptocurrency, also called digital currency, is a digital asset designed to
                      work as a medium of exchange that uses strong cryptography to secure financial transactions,
                      control the creation of additional units, and verify the transfer … -->
                    </p>
                    <!-- <div class="blog-btn">
                      <a class="blog-button"  [routerLink]="['/Blogdetails']">Read More »</a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
       <!--    <div class="col-md-6 col-lg-6 col-sm-12 blog11">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/blog1.png">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>What is Crypto Mining?</h4>
                    <p class="">
                      Crypto mining or Cryptocurrency mining is the process of verifying transactions on a blockchain
                      and adding them to a ledger. It is a lucrative endeavour as miners are rewarded with
                      cryptocurrency for their work. This reward incentivizes miners to ensure that the blockchain is
                      secure and up-to-date. Cryptocurrency mining is an energy-intensive process. It requires …
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button" [routerLink]="['/Blogdetails']">Read More »</a>
                    </div>3
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-md-1">

                    </div> -->
        </div>
    <!--     <div class="row blogg">
                <div class="col-sm-12 col-md-6 col-lg-6 blog1">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/blog6.png">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Crypto Mining vs Crypto Trading</h4>
                    <p class="">
                      Cryptocurrency has taken the world by storm in the past few years. As more and more people become
                      aware of its potential, they are looking for ways to make money from it. Two of the most popular
                      ways to do this are through crypto mining and crypto trading. Both have their own advantages and
                      disadvantages, …
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button" [routerLink]="['/Blogdetails']">Read More »</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 blog11">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/blog5.png">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Crypto Mining vs Crypto Trading</h4>
                    <p class="">
                      Cryptocurrency has taken the world by storm in the past few years. As more and more people become
                      aware of its potential, they are looking for ways to make money from it. Two of the most popular
                      ways to do this are through crypto mining and crypto trading. Both have their own advantages and
                      disadvantages, …
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button" [routerLink]="['/Blogdetails']">Read More »</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
        </div> -->
        <!-- <div class="row blogg">
          <div class="col-sm-12 col-md-6 col-lg-6 blog1">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/Rectangle 34.png   ">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Wealth Management</h4>
                    <p class="">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                      mattis, pulvinar dapibus leo
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 blog11">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/Rectangle 35.png   ">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Wealth Management</h4>
                    <p class="">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                      mattis, pulvinar dapibus leo
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row blogg">
          <div class="col-sm-12 col-md-6 col-lg-6 blog1">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/Rectangle 34.png   ">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Wealth Management</h4>
                    <p class="">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                      mattis, pulvinar dapibus leo
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 blog11">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/Rectangle 35.png   ">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Wealth Management</h4>
                    <p class="">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                      mattis, pulvinar dapibus leo
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row blogg">
          <div class="col-sm-12 col-md-6 col-lg-6 blog1">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/Rectangle 34.png   ">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Wealth Management</h4>
                    <p class="">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                      mattis, pulvinar dapibus leo
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 blog11">
            <div class="blog-image"><img class="img-fluid" src="../../../../assets/images/myimages/Rectangle 35.png   ">
              <div class="bottom-blog">
                <div class="card blog">
                  <div class="card-body blog">
                    <h4>Wealth Management</h4>
                    <p class="">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper
                      mattis, pulvinar dapibus leo
                    </p>
                    <div class="blog-btn">
                      <a class="blog-button">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>