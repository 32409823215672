<!-- <div class="textmove">
    <marquee scrollamount="15"> Limited Time Offer <b>10% off</b> on Advance Plan for New Customer  </marquee>
    
</div> -->
<button class="scroll-top" (click)="scrollToTop()" [class.visible]="isButtonVisible()"><img src="../../../assets/images/myimages/arrow.svg" alt=""></button>


<section id="header">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <a class="navbar-brand"  [routerLink]="['/home']"  ><img class="header-logo" src="../../../assets/images/myimages1/LOGO.png" alt=""></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-between" id="navbarNavAltMarkup">
           <!--  <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                    <a  [routerLink]="['/home']" class="nav-link">Home</a>
                </li>
                <li class="nav-item">
                    <a  [routerLink]="['/about']"  routerLinkActive="active" class="nav-link" >About</a>
                </li>                                                                                                                                                                              
                <li class="nav-item">
                    <a class="nav-link"  [routerLink]="['/Ourfeature']"  href="#">Our Feature</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"   [routerLink]="['/pricing']">Pricing</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Testimonials</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Blog</a>
                </li>
            </ul> -->
            <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/about']" routerLinkActive="active">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/Ourfeature']" routerLinkActive="active">Our Feature</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/pricing']" routerLinkActive="active">Pricing</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Testimonials</a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link"  [routerLink]="['/crypto']" routerLinkActive="active">What is Crypto?</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link"  [routerLink]="['/blog']" routerLinkActive="active">Blog</a>
                </li>
                
            </ul>
            <button class="get-btn1" [routerLink]="['/ContactUs']"  type="button"><a >Contact Us</a></button>
          </div>
        </div>
      </nav>
</section>


<!-- <div class="icon-bar"> -->
    <!-- <a href="#" class="facebook"><img src="../../../assets/images/myimages/face.png" alt=""></a>  -->
    <!-- <i class="fa fa-facebook"></i> -->
    <!-- <a href="#" class="twitter"><img src="../../../assets/images/myimages/ins.png" alt=""></a>  -->
    <!-- <i class="fa fa-twitter"></i> -->
    <!-- <a href="#" class="google"><img src="../../../assets/images/myimages/what.png" alt=""></a>  -->
    <!-- <i class="fa fa-google"></i> -->
    <!-- <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a> -->
    <!-- <a href="#" class="youtube"><i class="fa fa-youtube"></i></a>  -->
  <!-- </div> -->


  <nav class="social">
    <ul>
      <li class="twitter "><a class="ic" href="https://www.facebook.com/Marvellousmin" target="_blank">Facebook<img src="../../../assets/images/myimages/facebook.svg" alt=""></a></li>
      <li class="facebook"><a class="ic" href="https://www.instagram.com/marvellousinvestments/" target="_blank">Instagram<img src="../../../assets/images/myimages/instagram.svg" alt=""></a></li>
      <li class="google-plus"><a class="ic" href="https://whatsapp.com/channel/0029VaDXqif1t90Xgyu3FW1m" target="_blank">Join Channel<img src="../../../assets/images/myimages/whatsapp.svg" alt=""></a></li>
      <!-- <li class="instagram"><a href="https://codepen.io/collection/XdWJOQ/">Instagram<i class="fa fa-instagram" aria-hidden="true"></i></a></li> -->
    </ul>
  </nav>