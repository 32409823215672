<section class="about-banner">
    <div class="container">
        <div class="row">
            <div class="">
                <div class="fakeimg child">
                    <img src="../../../../assets/images/myimages1/Rectangle 22.png" class="img-fluid">
                    <div class="centeredtxt">CONTACT US</div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="world">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="fakeimg1aim"><img class="img-fluid" src="../../../../assets/images/myimages1/Rectangle 32.png">

                </div>
            </div>
            <div class=" fake-parent col-sm-12 col-md-6 col-lg-6">
                <div class="fakeimg child">
                    <div class="ban_text">
                        <div class="banner-ctn11">
                            <h1 class="headeingtext">
                                We are based on
                                India
                            </h1>
                            <p class="abouttext">Sigh view am high neat half to what. Sent late held than set why wife
                                our. If an blessing building steepest. Sent late held than set why wife our. If
                                an blessing building steepest. Agreement distrusts mrs six affection satisfied. Day
                                blushes visitor end company old prevent chapter. Consider declared out expenses her
                                concerns. 
                            </p>
                            <!-- <p>We are always in the pursuit of making our clients earn great <br> returns on their investments and
                paving
                their way towards a <br> secure and stable future. We believe in mutual growth and we <br> want to
                provide people
                with a reliable passive earning source <br> that helps them focus on their goals.</p> -->
                        </div>


                        <div class="download-btn1">
                            <button><a
                              href="tel:+918962281128">Call
                                    Us Now
                                </a></button>
                        </div>

                    </div>
                </div>
                <hr class="d-sm-none">
            </div>
        </div>
    </div>
</section>



<section class="Address">
    <div class="container">
        <div class="row text-center">
            <div class="col-md-3">
                <img src="../../../../assets/images/myimages/Group 118.svg" class="img-fluid" alt="">
                <h6 class="card-subtitle contantus">Our Address</h6>
                <p class="add-ctn">A,6 CM Tower Palasia Indore 452018</p>
            </div>
            <div class="col-md-3"> <img src="../../../../assets/images/myimages/Group 119.svg" class="img-fluid" alt="">
                <h6 class="card-subtitle contantus">Email</h6>
                <p class="add-ctn">care.marvellous.info@gmail.com</p>
            </div>
            <div class="col-md-3"> <img src="../../../../assets/images/myimages/Group 120.svg" class="img-fluid" alt="">
                <h6 class="card-subtitle contantus">Contact</h6>
                <p class="add-ctn">+91 8962281128</p>
            </div>
            <div class="col-md-3"> <img src="../../../../assets/images/myimages/Group 121.svg" class="img-fluid" alt="">
                <h6 class="card-subtitle contantus">Operation Hours</h6>
                <p class="add-ctn">Monday - Friday: 09:00 - 17:00</p>
            </div>
        </div>
    </div>
</section>

<section class="features">
    <div class="features-world">
        <div class="container">
            <div class="row">
              <div class=" fake-parent col-sm-12 col-md-6 col-lg-6" >
                <div class="ban_text">
                  <div class="banner-ctn">
                    <h5>GET IN TOUCH</h5>
                    <h1 class="headeingtext">
                      For any Queries <br> Or Concerns
                    </h1>
                    <p>Feel Free to Fill This Form
                    </p>
                  </div>
                  <div class="form-parent">
                    <form [formGroup]="marvellousForm" (ngSubmit)="marvellousSubmit()">
                      <div class="form-group">
                        <input type="email" class="form-control" formControlName="fullName" id="exampleInputEmail11"
                          aria-describedby="emailHelp" placeholder="Full Name" [ngClass]="{
                            'is-invalid': submitted && b.fullName.errors
                          }"/>
                        <div>
                          <div *ngIf="submitted && b.fullName.errors" class="invalid-feedback">
                            <div *ngIf="b.fullName.errors.required"> Full Name is required</div>
                          </div>
                        </div>
                      </div>
                   
                      <div class="form-group">
                        <input type="email" class="form-control" formControlName="phone" id="exampleInputEmail11"
                          aria-describedby="emailHelp" placeholder="Phone Number" [ngClass]="{
                            'is-invalid': submitted && b.phone.errors
                          }">
                        <div class="invalid-feedback">
                          <div *ngIf="submitted && b.phone.errors" class="invalid-feedback">
                            <div *ngIf="b.phone.errors.required"> Phone Number is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <input type="email" class="form-control" formControlName="state" id="exampleInputEmail11"
                          aria-describedby="emailHelp" placeholder="State" [ngClass]="{
                            'is-invalid': submitted && b.state.errors
                          }">
                        <div class="invalid-feedback">
                          <div *ngIf="submitted && b.state.errors" class="invalid-feedback">
                            <div *ngIf="b.state.errors.required"> state is required</div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="form-group">
                        <input type="email" class="form-control" formControlName="city" id="exampleInputEmail11"
                          aria-describedby="emailHelp" placeholder="City"  [ngClass]="{
                            'is-invalid': submitted && b.city.errors
                          }"/>
                        <div class="invalid-feedback">
                          <div *ngIf="submitted && b.city.errors" class="invalid-feedback">
                            <div *ngIf="b.city.errors.required"> City is required</div>
                          </div>
                        </div>
                      </div>
       -->
                      <!-- <div class="form-group">
                        <textarea name="user-message" id="user-message" formControlName="message" class="form-control" cols="1" rows="3"
                          placeholder="Enter your Message" [ngClass]="{
                            'is-invalid': submitted && b.message.errors
                          }"></textarea>
                          <div class="invalid-feedback">
                            <div *ngIf="submitted && b.message.errors" class="invalid-feedback">
                              <div *ngIf="b.message.errors.required"> Message is required</div>
                            </div>
                          </div>
                      </div> -->
                    <!-- x -->
                      <div class="form-group ">
                          <select  class="form-control" name="cars" id="cars">
                            <option hidden >Choose Plan</option>
                            <option value="saab">Basic Plan</option>
                            <option value="opel">Master Plan</option>
                            <option value="audi">Advance Plan</option>
                          </select>
                      </div>
                      <button type="submit" class="form1">Submit</button>
                    </form>
                  </div>
                </div>
                <hr class="d-sm-none">
              </div>
      
      
              
              <div class="col-sm-12 col-md-6 col-lg-6" >
                <div class="fakeimg1"><img class="img-fluid" src="../../../../assets/images/myimages1/contact.png">
                  <!-- <div class="bottom-center">
                    <div class="card savings">
                      <div class="saving-parent11 contact">
                        <div class="saving-img">
                          <img src="../../../assets/images/myimages/Vector (7).svg" alt="">
                        </div>
                        <div class="card-header savings">
                          <h4>Savings</h4>
                          <p>10% Compared With Last Month</p>
                        </div>
                      </div>
        
                      <div class="card-body">
                      
                        <p class="card-text contact">
                          ₹75,000
                        </p>
           
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
    </div>
  
  </section>

<!--   <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 500px">
    <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
      style="border:0" allowfullscreen></iframe>
  </div> -->

<!-- touch with us sectionb -->

  <!-- touch with us section -->