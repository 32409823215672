<section class="trusted">
  <div class="container">
    <div class="row">
      <div class=" fake-parent col-sm-12 col-md-6 col-lg-6">
        <div class="fakeimg child">
          <div class="ban_text">
            <div class="banner-ctn1" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
              <!-- <h5>WHY CHOOSE US</h5> -->
              <h1>
                THE EASIEST <br> WAY TO MANAGE <br> YOUR FINANCES
                <!-- SIMPLIFYING  FINANCIAL MANAGEMENT FOR A BETTER LIFE -->
              </h1>
              <p class="trusted-ctn">
                Welcome to Marvellous Mining, where your financial dreams come true. We are here to provide you with
                amazing investment opportunities that will help you achieve financial freedom, no matter your current
                situation
              </p>
            </div>
            <div class="download-btn11 d-flex">
              <button><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Get Started
                </a></button>
              <!-- <button class="video-btn"><span><img src="../../../assets/images/myimages/solar_play-bold.png" alt=""><a
                    class="watch-btn" href="">Watch Video</a></span></button> -->
            </div>
          </div>
        </div>
        <hr class="d-sm-none">
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 w3-animate-right" data-aos="fade-right" data-aos-easing="linear"
        data-aos-duration="1000">
        <div class="fakeimg1">
          <img class="img-fluid" src="../../../assets/images/myimages1/main-banner.png">
        </div>
        <!-- <div class="bottom-left">
          <div class="card banner-card">
            <div class="card-body">
              <div class="d-flex justify-content-between mb-1">
                <div class="parent-banner">
                  <span>Saving Plan</span>
                </div>
                <div class="text-banner">
                  <span>See All</span>
                </div>
              </div>
              <hr>
              <div class="d-flex justify-content-between mt-2 mb-1">
                <div class="parent-banner">
                  <span> Vacation</span>
                </div>
                <div class="text-banner">
                 
                </div>

              </div>
              <div class="d-flex justify-content-between mt-2 mb-1">
                <div class="parent-banner1">
                  <span>₹36000</span>
                </div>
                <div class="text-banner1">
                  <span>48%</span>
                </div>
              </div>
              <div class="progress" style="height: 0.3rem;">
                <div class="progress-bar banner" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                  aria-valuemax="100" style="width: 48%; height: 100% ;"></div>
              </div>
              <div class="d-flex justify-content-between mt-2 mb-1">
                <div class="parent-banner">
                  <span>New Gadgets</span>
                </div>
                <div class="text-banner">
           
                </div>
              </div>
              <div class="d-flex justify-content-between mt-2 mb-1">
                <div class="parent-banner1">
                  <span>₹24000</span>
                </div>
                <div class="text-banner12">
                  <span>32%</span>
                </div>
              </div>
              <div class="progress" style="height: 0.3rem;">
                <div class="progress-bar banner1" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                  aria-valuemax="100" style="width: 32%; height: 100%"></div>
              </div>
              <div class="d-flex justify-content-between mt-2 mb-1">
                <div class="parent-banner">
                  <span>Charity</span>
                </div>
                <div class="text-banner">
        
                </div>
              </div>
              <div class="d-flex justify-content-between mt-2 mb-1">
                <div class="parent-banner1">
                  <span>₹1500</span>
                </div>
                <div class="text-banner13">
                  <span>20%</span>
                </div>
              </div>
              <div class="progress" style="height: 0.3rem;">
                <div class="progress-bar banner2" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                  aria-valuemax="100" style="width: 20%; height: 100%"></div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>

<!-- banner- section below -->
<section class="features">
  <div class="features-parent">
    <div class="container">
      <div class="saving-parent1">
        <div class="row" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
          <div class="col-md-12 col-lg-4 col-sm-12 column">
            <div class="feature-icon">
              <!-- <i data-feather="dollar-sign" [size]="33" class=" cursor-pointer"></i> -->
              <img src="../../../assets/images/icons/Vector.svg" alt="">
            </div> 
            <h5 class="textsaving">Saving Strategies</h5>
            <p class="content">Customized investment plans for all, catering to individual goals and circumstances,
              whether novice or seasoned. </p>
          </div>
          <div class="col-md-12 col-lg-4 col-sm-12 column">
            <div class="feature-icon">
              <img src="../../../assets/images/icons/Vector (1).svg" alt="">
            </div>
            <h5 class="textsaving">Competitive Price</h5>
            <p class="content">Tailored investment plans for everyone, meeting unique goals and circumstances, whether
              new or experienced.</p>
          </div>
          <div class="col-md-12 col-lg-4 col-sm-12 column">
            <div class="feature-icon">
              <img src="../../../assets/images/icons/streamline_customer-support-1-solid.svg" alt="">
            </div>
            <h5 class="textsaving">24/7 Support</h5>
            <p class="content">Dedicated financial experts provide guidance, answer questions, and ensure your path to
              financial success.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- banner-section below -->

<!-- who we are -->
<section class="aim">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
        <div class="fakeimg1aim">
          <img class="img-fluid" src="../../../assets/images/myimages1/about1.png ">
        </div>
      </div>
      <div class=" fake-parent col-sm-12 col-md-6 col-lg-6" data-aos="fade-left" data-aos-easing="linear"
        data-aos-duration="1000">
        <div class="fakeimg child">
          <div class="ban_text">
            <div class="banner-ctn">
              <h5>WHO WE ARE</h5>
              <h1 class="headeingtext">
                We Aim To Be The Best <br> Manager For Our Clients
              </h1>
              <p class="abouttext">At Marvellous Mining, our main goal is to help you take control of your
                financial future. We offer
                expert investment management services to protect and grow your hard-earned money. We believe that
                everyone deserves a chance to succeed financially, and we're committed to helping you reach your goals.
              </p>
            </div>
            <div class="form-parent">
              <h1 class="experience">+<span>YEARS EXPERIENCE</span></h1>
            </div>

            <div class="download-btn1">
              <button><a href="#form">More About us
                </a></button>
            </div>

          </div>
        </div>
        <hr class="d-sm-none">
      </div>
    </div>
  </div>
</section>
<!-- who we are -->

<!-- earning -->
<section class="earning">
  <div class="container">
    <div class="earning-heading">
      <h1 class="headeingtext"> Your Earning options</h1>
      <p>We have simple investment plans to deliver you the best investment experience.</p>
    </div>
    <!-- <div class="row justify-content-between" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
      <div class="col-md-12 col-lg-4 col-sm-12  w3-animate-right">
        <div class="card border">
          <div class="card-body p-5">
            <img src="../../../assets/images/myimages/Group 16 (3).svg" alt="">
            <h6 class="card-subtitle  text-body-secondary">BASIC PLAN</h6>
            <p class="card-text1">₹ 1,000-49,999</p>
            <p>Assured Monthly 3.08%</p>
            <p>Assured Annual 3.08%</p>
            <div class="btn-p earning">
              <a href="#" class="card-link">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12  col-lg-4 col-sm-12">
        <div class="card border">
          <div class="card-body p-5">
            <img src="../../../assets/images/myimages/Group 16 (4).svg" alt="">
            <h6 class="card-subtitle  text-body-secondary">MASTER PLAN</h6>
            <p class="card-text1">₹ 50,000 - 2,49,000</p>
            <p>Assured Monthly 3.5%</p>
            <p>Assured Annual 42%</p>
            <div class="btn-p earning">
              <a href="#" class="card-link">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12  col-lg-4 col-sm-12">
        <div class="card black">
          <div class="card-body p-5">
            <img src="../../../assets/images/myimages/Group 16 (5).svg" alt="">
            <h6 class="card-subtitle  text-body-secondary">ADVANCE PLAN</h6>
            <p class="card-text11">₹ 2,50,000 - 10,00,000</p>
            <p>Assured Monthly 3.75%</p>
            <p>Assured Annual 45%</p>
            <div class="btn-p earning">
              <a href="#" class="card-link1">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="row justify-content-between price-card" data-aos="fade-left" data-aos-easing="linear"
      data-aos-duration="1000">
      <div class="col-md-12  col-lg-4 col-sm-12">
        <div class="card black plan">
          <div class="card-body p-5">
            <img src="../../../assets/images/myimages/Group 16 (6).svg" alt="">
            <h6 class="card-subtitle  text-body-secondary plan">BASIC PLAN</h6>
            <!-- <p >Lorem ipsum dolor sit amet, consect adipiscing elit. Ut elit tellus</p> -->
            <h4 class="plan-num price">₹ 1,000 - 49,999</h4>
            <div class="planbtn-p">
              <button class="plan-btn1"><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Buy Now
              </a></button>
              
            </div>
            <div class="project">
              <p><img src="../../../../assets/images/myimages/Group 22.png" alt=""><span>Assured Monthly 3.08%</span></p>
              <p><img src="../../../../assets/images/myimages/Group 22.png" alt=""><span>Assured Annual 37%</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12  col-lg-4 col-sm-12">
        <div class="card border plan">
          <div class="card-body p-5">
            <img src="../../../assets/images/myimages/pricing.svg" alt="">
            <h6 class="card-subtitle  text-body-secondary plan">MASTER PLAN</h6>
            <!-- <p >Lorem ipsum dolor sit amet, consect adipiscing elit. Ut elit tellus</p> -->
            <h4 class="plan-num price">₹ 50,000 - 2,49,999</h4>
            <div class="planbtn-p">
              <button class="plan-btn1"><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Buy Now
              </a></button>
              
            </div>
            <div class="project">
              <p><img src="../../../../assets/images/myimages1/Group 108.png" alt=""><span>Assured Monthly 3.5%</span>
              </p>
              <p><img src="../../../../assets/images/myimages1/Group 108.png" alt=""><span>Assured Annual 42%</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12  col-lg-4 col-sm-12">
        <div class="card black plan">
          <div class="card-body p-5">
            <img src="../../../assets/images/myimages/Group 16 (8).svg" alt="">
            <h6 class="card-subtitle  text-body-secondary plan">ADVANCE PLAN</h6>
            <!-- <p >Lorem ipsum dolor sit amet, consect adipiscing elit. Ut elit tellus</p> -->
            <h4 class="plan-num price">₹ 2,50,000 - 9,99,999</h4>
            <div class="planbtn-p">
              <button class="plan-btn1"><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Buy Now
              </a></button>
              
            </div>
            <div class="project">
              <p><img src="../../../../assets/images/myimages/Group 22.png" alt=""><span>Assured Monthly 3.75%</span></p>
              <p><img src="../../../../assets/images/myimages/Group 22.png" alt=""><span>Assured Annual 45%</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- earning sectiopn -->


<!--  whats our other feature -->
<section class="features ">
  <div class="monthly-parent">
    <div class="container">
      <div class="faeture-heading">
        <h5>OUR FEATURES</h5>
        <h1 class="headeingtext">What’s Our Features</h1>
        <!--  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus <br> nec ullamcorper mattis,
          pulvinar dapibus leo.</p> -->
      </div>
      <div class="saving-parent">
        <div class="row" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
          <div class="col-md-12 col-lg-4 col-sm-12 save">
            <div class="feature-icon">
              <!-- <i data-feather="dollar-sign" [size]="33" class=" cursor-pointer"></i> -->
              <img src="../../../assets/images/icons/Vector (2).svg" alt="">
            </div>
            <h5>Monthly Payout</h5>

          </div>
          <div class="col-md-12 col-lg-4 col-sm-12 save">
            <div class="feature-icon">
              <img src="../../../assets/images/icons/Vector (3).svg" alt="">
            </div>
            <h5>Guaranteed Returns</h5>

          </div>
          <div class="col-md-12 col-lg-4 col-sm-12 save">
            <div class="feature-icon">
              <img src="../../../assets/images/icons/Vector (6).svg " alt="">
            </div>
            <h5> Transparent Process</h5>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--  whats our other feature -->


<!-- trusted -->
<section class="trusted">
  <div class="container">
    <div class="row">
      <div class=" fake-parent col-sm-12 col-md-6 col-lg-6" data-aos="fade-right" data-aos-easing="linear"
        data-aos-duration="2000">
        <div class="fakeimg child">
          <div class="ban_text">
            <div class="banner-ctn">
              <h5>WHY CHOOSE US</h5>
              <h1 class="headeingtext">
                Trusted Over 4000+ Clients
              </h1>
              <p class="trusted-ctn">Welcome to Marvellous Mining, where your financial goals become our priority. As a
                leading investment management service, we specialize in delivering exceptional investment experiences
                tailored to your needs. With a commitment to integrity and responsibility, we handle your hard-earned
                money with the utmost care.
              </p>
            </div>
            <div class="load">
              <ul>
                <li><img src="../../../assets/images/myimages/Group 22 (1).svg" alt=""><span>Professionally Loaded
                    Team</span></li>
                <li><img src="../../../assets/images/myimages/Group 22 (1).svg" alt=""><span> Offering Premium
                    Investment Solutions</span></li>
                <li><img src="../../../assets/images/myimages/Group 22 (1).svg" alt=""><span> Ensuring 100% Client
                    Satisfaction</span></li>
                <li><img src="../../../assets/images/myimages/Group 22 (1).svg" alt=""><span> We are Fully Bonded and
                    Insured</span></li>
              </ul>
            </div>
            <div class="download-btn1">
              <button><a href="#form">Learn More
                </a></button>
            </div>

          </div>
        </div>
        <hr class="d-sm-none">
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="2000">
        <div class="fakeimg1">
          <img class="img-fluid" src="../../../assets/images/myimages1/Groupsection.png">
        </div>
      </div>

    </div>
  </div>

</section>
<!-- trusted -->


<!-- Case Study -->
<section class="case">
  <div class="container">
    <div class="dowload-parent">
      <div class="row">
        <div class="col-md-6">
          <h5>Download</h5>
          <h1 class="headeingtext">Marvellous Mining App</h1>
        </div>
        <div class="col-md-6">
          <div class="download-btn">
            <button><a href="https://play.google.com/store/apps/details?id=com.marvellous.mining" target="_blank">Download The
                App</a></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="images-parent">
      <div class="row ">
        <div class="col-md-3 p-0">
          <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 11.svg" alt="">
        </div>
        <div class="col-md-3 p-0">
          <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 13.svg" alt="">
        </div>
        <div class="col-md-3 p-0">
          <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 14.svg" alt="">
        </div>
        <div class="col-md-3 p-0">
          <img class="img-fluid" src="../../../assets/images/myimages1/Rectangle 13.png" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Case Study -->

<!-- client section -->

<!-- <div class="frame">
  <img src="your-image-url.jpg" alt="Image">
</div> -->

<section class="client">
  <div class="container">
    <div class="faeture-heading">
      <h5>TESTIMONIAL</h5>
      <h1 class="headeingtext">Our Client Reviews</h1>
    </div>


    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-md-1 p-0"></div>
            <!--  <div class="col-md-4 p-0">
              <div class="img-section">
                <img class="img-fluid" src="../../../assets/images/myimages/Rectangle pp.png" alt="">
              </div>
            </div> -->
            <div class="col-md-10 p-0">
              <div class="client-ctn">
                <h2 class="testmonialtext"> "I am associated with the company since last 3months Monthly payout also
                  goes to limit. it is a good platform. Itis safe and secure, and the staff is also helpful. trustable
                  earning apps"
                </h2>
                <p class="testmonialname">Rajesh Kumar</p>
              </div>
            </div>
            <div class="col-md-1 p-0"></div>
          </div>

        </div>
        <div class="carousel-item ">
          <div class="row">
            <div class="col-md-1 p-0"></div>
            <!--  <div class="col-md-4 p-0">
              <div class="img-section">
                <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 16.png" alt="">
              </div>
            </div> -->
            <div class="col-md-10 p-0">
              <div class="client-ctn">
                <h2 class="testmonialtext"> "Amazing app intrest will start from the same day and at the end of month
                  you can withdraw your interest. I searched so Manny app but the marvellous team giving the best
                  interest or roi on your deposit ... I recommend to everyone to invest this loyal and 100% trustable..
                </h2>
                <p class="testmonialname">Jagmohan Kumar</p>
              </div>
            </div>
            <div class="col-md-1 p-0"></div>
          </div>

        </div>
        <div class="carousel-item ">
          <div class="row">
            <div class="col-md-1 p-0"></div>
            <!--  <div class="col-md-4 p-0">
              <div class="img-section">
                <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 18.png" alt="">
              </div>
            </div> -->
            <div class="col-md-10 p-0">
              <div class="client-ctn">
                <h2 class="testmonialtext"> "Good investment opportunity with timely payment and great Returns.. It's
                  been 2 months so far so good. your returns are updated daily.. And also excellent support from their
                  Executive. . Munish ji thanks for your continuous and almost instant support and guidaince.. Kudos to
                  team Marvellous Mining..."
                </h2>
                <p class="testmonialname">Ali Akbar</p>
              </div>
            </div>
            <div class="col-md-1 p-0"></div>
          </div>

        </div>
        <div class="carousel-item ">
          <div class="row">
            <div class="col-md-1 p-0"></div>
            <!--  <div class="col-md-4 p-0">
              <div class="img-section">
                <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 19.png" alt="">
              </div>
            </div> -->
            <div class="col-md-10 p-0">
              <div class="client-ctn">
                <h2 class="testmonialtext"> "I have been using this app for the last four months or so.The experience
                  has been more than satisfactory.The most important thing is that the executive responds to the query
                  almost instantaneously."
                </h2>
                <p class="testmonialname">Ashok Krishn</p>
              </div>
            </div>
            <div class="col-md-1 p-0"></div>
          </div>

        </div>
        <div class="carousel-item ">
          <div class="row">
            <div class="col-md-1 p-0"></div>
            <!--  <div class="col-md-4 p-0">
              <div class="img-section">
                <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 20.png" alt="">
              </div>
            </div> -->
            <div class="col-md-10 p-0">
              <div class="client-ctn">
                <h2 class="testmonialtext"> "I have been using it from the last 2 months and it is amazing. It provides
                  interest daily and the collected interest credits in your account at 1st Monday in the month. The app
                  is superb till now because it provides upto 45% interest annually. You can invest your money to get
                  regular income. Thanks."
                </h2>
                <p class="testmonialname">Alok Mishra</p>
              </div>
            </div>
            <div class="col-md-1 p-0"></div>
          </div>

        </div>
      </div>
      <button class="carousel-control-prev" type="button1" data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button1" data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>


    </div>
    <!--  <div class="col-md-5 p-0">
        <div class="img-section">
          <img class="img-fluid" src="../../../assets/images/myimages/Rectangle 16 (1).png" alt="">
        </div>
      </div>
      <div class="col-md-7 p-0">
        <div class="client-ctn">
          <h1> This Company Very <br> Favorably Impressed With <br> The Quality Of The Work <br> Performed By Your <br>
            Employees</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br> Etiam et fringilla diam, et aliquet libero.
            Cras pulvinar <br> elementum enim, at faucibus est accumsan et.</p>
        </div>
      </div> -->

  </div>
</section>
<!-- client section -->


<!-- FREE SECTION -->
<section id="form">
  <section  class="mybanner">
    <div class="container">
      <div class="row">
        <div class=" fake-parent col-sm-12 col-md-6 col-lg-6" data-aos="fade-right" data-aos-easing="linear"
          data-aos-duration="1500">
          <div class="fakeimg child">
            <div class="ban_text">
              <div class="banner-ctn">
                <h5>GET IN TOUCH</h5>
                <h1 class="headeingtext">
                  For Any Queries or Concerns
                </h1>
                <p>Feel Free to Fill This Form
                </p>
              </div>
              <div class="form-parent">
                <form [formGroup]="marvellousForm" (ngSubmit)="marvellousSubmit()">
                  <div class="form-group">
                    <input type="email" class="form-control" formControlName="fullName" id="exampleInputEmail11"
                      aria-describedby="emailHelp" placeholder="Full Name" [ngClass]="{
                        'is-invalid': submitted && b.fullName.errors
                      }"/>
                    <div>
                      <div *ngIf="submitted && b.fullName.errors" class="invalid-feedback">
                        <div *ngIf="b.fullName.errors.required"> Full Name is required</div>
                      </div>
                    </div>
                  </div>
               
                  <div class="form-group">
                    <input type="email" class="form-control" formControlName="phone" id="exampleInputEmail11"
                      aria-describedby="emailHelp" placeholder="Phone Number" [ngClass]="{
                        'is-invalid': submitted && b.phone.errors
                      }">
                    <div class="invalid-feedback">
                      <div *ngIf="submitted && b.phone.errors" class="invalid-feedback">
                        <div *ngIf="b.phone.errors.required"> Phone Number is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="email" class="form-control" formControlName="state" id="exampleInputEmail11"
                      aria-describedby="emailHelp" placeholder="State" [ngClass]="{
                        'is-invalid': submitted && b.state.errors
                      }">
                    <div class="invalid-feedback">
                      <div *ngIf="submitted && b.state.errors" class="invalid-feedback">
                        <div *ngIf="b.state.errors.required"> state is required</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <input type="email" class="form-control" formControlName="city" id="exampleInputEmail11"
                      aria-describedby="emailHelp" placeholder="City"  [ngClass]="{
                        'is-invalid': submitted && b.city.errors
                      }"/>
                    <div class="invalid-feedback">
                      <div *ngIf="submitted && b.city.errors" class="invalid-feedback">
                        <div *ngIf="b.city.errors.required"> City is required</div>
                      </div>
                    </div>
                  </div>
   -->
                  <!-- <div class="form-group">
                    <textarea name="user-message" id="user-message" formControlName="message" class="form-control" cols="1" rows="3"
                      placeholder="Enter your Message" [ngClass]="{
                        'is-invalid': submitted && b.message.errors
                      }"></textarea>
                      <div class="invalid-feedback">
                        <div *ngIf="submitted && b.message.errors" class="invalid-feedback">
                          <div *ngIf="b.message.errors.required"> Message is required</div>
                        </div>
                      </div>
                  </div> -->
                <!-- x -->
                  <div class="form-group ">
                      <select  class="form-control" name="cars" id="cars">
                        <option hidden >Choose Plan</option>
                        <option value="saab">Basic Plan</option>
                        <option value="opel">Master Plan</option>
                        <option value="audi">Advance Plan</option>
                      </select>
                  </div>
                  <button type="submit" class="form1">Submit</button>
                </form>
              </div>
            </div>
          </div>
          <hr class="d-sm-none">
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1500">
          <div class="fakeimg1"><img class="img-fluid" src="../../../assets/images/myimages1/Groupform.png">
            <!-- <div class="bottom-center">
              <div class="card savings">
                <div class="saving-parent11">
                  <div class="saving-img">
                    <img src="../../../assets/images/myimages/Vector (7).svg" alt="">
                  </div>
                  <div class="card-header savings">
                    <h4>Savings</h4>
                    <p>10% Compared With Last Month</p>
                  </div>
                </div>
  
                <div class="card-body">
                
                  <p class="card-text">
                    ₹75,000/-
                  </p>
                  
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</section>






