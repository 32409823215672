import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AdminServiceService } from 'app/Services/admin-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogs:any;
  constructor(private fb: FormBuilder, private adminService: AdminServiceService,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.getBlogs()
  }
getBlogs(){
  this.adminService.getBlogs().subscribe((data: any) => {
    this.blogs=data.items
    // console.log(data)
    // if (data.status) {
    //   this.toastr.success(data.message, "Success!");
   

    // } else {
    //   this.toastr.error(data.message, "error!")

    // }

  });
}
}
